<template>
  <content-wrapper class="city">
    <div class="articles">
      <h1>О городе</h1>
      <swiper :options="{slidesPerView: 'auto', touchRatio: 2}">
        <swiper-slide v-for="(fact, index) in [...facts]" :key="index">
          <router-link :to="`/fact/${fact.id}`">
            <fact
              :img="fact.images[Object.keys(fact.images)[0]]"
              :title="fact.title"
            />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>

    <div v-for="type in places" :key="type.id">
      <div v-if="type.places && type.places.length">
        <h1>{{type.name}}</h1>
        <swiper-row :length="type.places.length">
          <swiper-slide v-for="(object, index) in [...type.places].slice(0,limit)" :key="index">
            <object-card
              type="icon"
              width="488px"
              height="275px"
              :img="object.images[Object.keys(object.images)[0]].replace('extra','medium')"
              :title="object.title"
              :category="object.type.name"
              :icon="object.type.image"
              @click.native="goToObject(object)"
            />
          </swiper-slide>
          <swiper-slide v-if="type.places.length > limit">
            <show-more width="488px" height="275px" @click.native="goToObjects(type.id)" />
          </swiper-slide>
        </swiper-row>
      </div>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import SwiperRow from "@/components/Wrappers/SwiperRow";
import ObjectCard from "@/components/Parts/ObjectCard";
import Fact from "@/components/Parts/LittleCard";
import ShowMore from "@/components/Parts/ShowMore";

export default {
  name: "CityScreen",
  components: { ContentWrapper, SwiperRow, ObjectCard, Fact, ShowMore },

  data() {
    return {
      limit: 4,
      cityId: 12,
      facts: null,
      places: []
    };
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.getFacts(this.cityId).then(res => {
        this.facts = res.data;
      });

      api.getObjectsByType(this.cityId, 1, this.limit + 1).then(res => {
        this.places = res.data;
        console.log(this.places);
      });
    },
    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
      this.$store.dispatch("fetchNearObjects", object.id);
    },
    goToObjects(type) {
      this.$router.push({ path: `/objects/${this.cityId}/${type}` });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.city {
  padding-top: 56px;

  & > div {
    position: relative;
    z-index: 3;
    margin-bottom: 56px;
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(#fff, 0.97);
    margin-top: 20px;
    margin-left: 32px;
    margin-bottom: 28px;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    width: 488px;
    margin-right: 8px;
    margin-left: 32px;
    &:last-child {
      margin-right: 32px;
    }
  }

  .articles {
    .swiper-slide {
      width: 310px;
    }
  }
}
</style>